(function ($) {
  Drupal.behaviors.basicCarouselFormatterV1 = {
    attach: function (context) {
      var $template = $('.js-basic-carousel-formatter--v1', context);

      /* Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      */
      $template.each(function () {
        var $self = $(this);
        var $carousel = $self.find('.js-basic-carousel');
        var $arrowsDiv = $self.find('.carousel-controls');
        var $dotsDiv = $self.find('.carousel-dots');
        var basicSlide = '.js-basic-carousel__slide';

        // Do some JS positioning of the dots below the image
        if ($self.hasClass('js-basic-carousel-dots-position')) {
          var $slides = $self.find(basicSlide);

          $slides.each(function () {
            var $contentBlockTypography = $(this).find('.content-block__typography').first();

            $contentBlockTypography.parent().prepend($dotsDiv.clone());
          });

          // Mark dot as active
          var activeClass = 'slick-active';
          var ariaAttribute = 'aria-hidden';

          $self
            .on('init', function () {
              $self.addClass('basic-carousel--dots-position-active');
              $('.slick-dots li:first-of-type', $self)
                .addClass(activeClass)
                .attr(ariaAttribute, false);
            })
            .on('afterChange', function (event, slick, currentSlide) {
              var $dots = $('.slick-dots', $self);

              $('li', $dots).removeClass(activeClass).attr(ariaAttribute, true);
              $dots.each(function () {
                $('li', $(this)).eq(currentSlide).addClass(activeClass).attr(ariaAttribute, false);
              });
            });

          $dotsDiv = $self.find('.carousel-dots');
        }

        // autoplay settings
        var autoplay = $carousel.attr('data-slides-autoplay');
        var autoplayDelay = $carousel.attr('data-slides-delay') * 1000;

        if (isNaN(autoplayDelay) || autoplayDelay === null) {
          autoplayDelay = 5000;
        }

        if (typeof autoplay === 'undefined') {
          autoplay = false;
        } else {
          autoplay = true;
        }

        var settings = {
          arrows: true,
          appendArrows: $arrowsDiv,
          dots: true,
          appendDots: $dotsDiv,
          slide: basicSlide,
          // prevent bug with dots not appearing active, disable infinite if scrolling more than one
          infinite: $self.data('slides') && $self.data('slides') > 1 ? false : true,
          slidesToShow: $self.data('slides'),
          slidesToScroll: 1,
          autoplay: autoplay,
          autoplaySpeed: autoplayDelay,
          adaptiveHeight: false,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                adaptiveHeight: false,
                // centerMode: true,
                // infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        if ($self.hasClass('slick-initialized')) {
          try {
            $self.slick('destroy');
          } catch (e) {
            console.log(e);
          }
        }

        // Init this carousel with our settings
        $carousel.slick(settings);
        $('img', $carousel).on(
          'load.done',
          _.debounce(function (event) {
            if (
              $self.hasClass('js-basic-carousel--arrows-alignment') &&
              !site.client.isMobileBreakpoint()
            ) {
              $carousel.slick('setPosition');
              var $arrows = $self.find('.slick-arrow');
              var $images = $('.content-block-tout__image.lazyloaded', $self);

              $arrows.css({
                top: $images.height() / 2
              });
            }
          }, 350)
        );
      });
    }
  };
})(jQuery);
